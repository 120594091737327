@import url('https://content.votenow.tv/app/projects/diageo/diageo2.css');

html, body {
  background-color: #ADADAD;
  font-family: 'Helvetica Now Display', sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
}

header {
  background: #ffffff;
}

.header_content {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_content img {
  cursor: pointer;
  height: 50px;
}

.container {
  background-color: #FDF3EC;
  flex-grow: 2;
  padding: 0;
}

@media (min-width: 768px) {
  .header_content {
    height: 76px;
  }

  .header_content img {
    height: 70px;
  }
}
