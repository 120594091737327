.container {
  font-family: 'Helvetica Now Display', sans-serif;
  display: block;
  margin: 0 auto;
  max-width: 1180px;
  width: 100%;
}

.container h1 {
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 400;
  margin: 20px 0 10px 0;
  letter-spacing: -1px;
  font-family: 'Fnord', sans-serif;
}

.container h2 {
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  margin: 0 0 30px 0;
  letter-spacing: -1px;
  font-family: 'Fnord', sans-serif;
}

.iframes_container {
  display: flex;
  flex-wrap: wrap;
}

.video_container {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}

.chat_container {
  position: relative;
  height: 450px;
  padding-bottom: 0;
  width: 100%;
}

.iframes_container iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chat_container iframe {
  visibility: hidden;
  display: none;
}

.chat_container .show_iframe {
  visibility: visible;
  display: block;
}

.product_overlay {
  font-family: 'Fnord', sans-serif;
  width: 40vw;
  height: 25vw;
  max-width: 293px;
  max-height: 125px;
  border-radius: 11px;
  color: #ffffff;
  position: absolute;
  bottom: 55px;
  left: 10px;
  z-index: 2;
  background-color: #9FA402;
  display: flex;
}

.product_overlay img {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
  max-width: 45%;
}

.buy_button {
  font-family: 'Helvetica New Display', sans-serif;
  background: #EBAF5A;
  color: #000000;
  font-size: 12px;
  border-radius: 4px;
  width: 85px;
  height: 28px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.close_button {
  color: #ffffff;
  background: none;
  border: none;
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
}

.close_button svg {
  width: 15px;
  height: 15px;
  fill: #ffffff;
}

.product_data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.product_data span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav_items_mobile {
  display: flex;
  justify-content: end;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #DE9344;
  font-size: 14px;
  width: 100%;
}

.nav_items_desktop {
  display: none;
}

.nav_items_mobile li {
  cursor: pointer;
  margin: 20px;
}

.nav_items_desktop li {
  cursor: pointer;
  margin: 20px 0 20px 30px;
}

.nav_active {
  text-decoration: underline;
}

.availability {
  display: none;
}

.availability, .availability_mobile {
  text-align: left;
  margin: 20px;
  font-size: 12px;
}

.product_grid {
  max-width: 1180px;
  width: 100%;
  margin: auto;
}

.product_grid ul {
  display: flex;
  list-style: none;
  margin: 2.5vw;
  flex-flow: row wrap;
  padding: 0;
}

.product_grid li {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 2.5vw;
  flex-basis: calc(100%/2 - 5vw);
}

.product_grid p {
  margin: 0;
}

@media (max-width: 299px) {
  .product_overlay {
    bottom: 5px !important;
  }
}

@media (max-width: 450px) {
  .product_overlay {
    width: 45vw;
    height: 20vw;
    left: 5px;
    bottom: 45px;
  }

  .product_data {
    font-size: 10px;
    padding: 5px 10px 5px 5px;
  }

  .product_data span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product_overlay .buy_button {
    font-size: 9px;
    width: 70px;
    height: 18px;
    margin-top: 5px;
  }

  .product_overlay .close_button {
    padding: 0;
    right: 4px;
    top: 2px;
  }

  .product_overlay .close_button svg {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 568px) {
  .product_grid ul {
    margin: 25px;
  }

  .product_grid li {
    margin: 15px;
    flex-basis: calc(100%/3 - 30px);
  }

  .product_data {
    padding: 15px;
  }

  .product_overlay .buy_button {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .container h1 {
    letter-spacing: -1.94px;
    font-size: 60px;
  }

  .product_overlay {
    width: 293px;
    height: 125px;
  }

  .product_data {
    font-size: 16px;
  }

  .buy_button {
    width: 93px;
  }

  .product_grid ul {
    margin: 20px;
  }

  .product_grid li {
    margin: 20px 10px;
    flex-basis: calc(100%/5 - 20px);
  }

}

@media (min-width: 968px) {
  .availability_mobile {
    display: none;
  }

  .availability {
    display: block;
  }

  .container {
    width: 95%;
  }

  .info_container {
    margin-top: 15px;
    font-size: 16px;
  }

  .iframes_container {
    flex-wrap: nowrap;
  }

  .video_container {
    padding-bottom: 40.25%;
  }

  .chat_container {
    padding-bottom: 40.25%;
    width: 40%;
    height: auto;
  }

  .nav_items_mobile {
    display: none;
  }

  .nav_items_desktop {
    display: flex;
    justify-content: end;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #DE9344;
    font-size: 16px;
  }

  .availability {
    text-align: left;
    margin: 0;
    font-size: 14px;
  }

  .product_grid ul {
    margin: 20px -20px;
  }

  .product_grid li {
    margin: 20px;
    flex-basis: calc(100%/5 - 40px);
  }
}
