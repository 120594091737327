.section_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.rsvp_scroll_button {
    font-size: 30px;
    font-family: 'Helvetica New Display', sans-serif;
    z-index: 1;
    cursor: pointer;
    box-shadow: 0 -1px 10px #000000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    height: 78px;
    color: #000000;
    background: #ebaf59;
}

.section_container > div {
    padding: 18px;
    width: 90%;
}

.section_container h1 {
    font-family: 'Fnord', sans-serif;
    letter-spacing: 0;
    display: flex;
    flex-direction: column;
    font-size: 60px;
    line-height: 60px;
    font-weight: 400;
    margin: 0;
}

.section_container p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.section_container .rsvp_button {
    font-size: 16px;
    cursor: pointer;
}

.section_container .rsvp_button svg {
    margin-left: 5px;
}

.section_image {
    order: -1;
    height: auto;
    width: 100%;
}

.section_logo {
    width: 300px;
    max-width: 100%;
    margin-top: 15px;
}

.about_event_description li {
    text-align: left;
    padding-left: 12px;
    padding-bottom: 2px;
}

.about_event_description li::marker {
    font-weight: bold;
}

@media (min-width: 750px) {
    section {
        display: flex;
    }

    .rsvp_scroll_button {
        display: none;
    }

    .live_event .section_container {
        text-align: left;
    }

    .about_event {
        padding: 40px 0;
    }

    .section_container {
        flex-direction: row;
        padding: 0 20px;
        max-width: 975px;
    }

    .section_container > div {
        width: 100%;
    }

    .section_container p {
        font-size: 16px;
        line-height: 20px;
    }

    .section_container .rsvp_button {
        justify-content: left;
    }

    .section_image {
        order: 0;
        max-height: 515px;
        height: 100%;
        padding-left: 20px;
        width: auto;
        max-width: 50%;
    }

    .section_logo {
        margin-top: 0;
    }

    .about_event {
        padding: 60px 0;
    }

    .section_image {
        padding-left: 50px;
    }

    .section_container h1 {
        font-size: 40px;
        line-height: 36px;
    }
}

@media (min-width: 1024px) {
    .section_container h1 {
        font-size: 60px;
        line-height: 56px;
    }

    .section_container p, .section_container .rsvp_button {
        font-size: 18px;
        line-height: 24px;
    }
}
