@import url(https://content.votenow.tv/app/projects/diageo/diageo2.css);
.closed_section_container__1VGP0 {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.closed_rsvp_scroll_button__26ZLU {
    font-size: 30px;
    font-family: 'Helvetica New Display', sans-serif;
    z-index: 1;
    cursor: pointer;
    box-shadow: 0 -1px 10px #000000;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
    height: 78px;
    color: #000000;
    background: #ebaf59;
}

.closed_section_container__1VGP0 > div {
    padding: 18px;
    width: 90%;
}

.closed_section_container__1VGP0 h1 {
    font-family: 'Fnord', sans-serif;
    letter-spacing: 0;
    display: flex;
    flex-direction: column;
    font-size: 60px;
    line-height: 60px;
    font-weight: 400;
    margin: 0;
}

.closed_section_container__1VGP0 p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.closed_section_container__1VGP0 .closed_rsvp_button__cJuh2 {
    font-size: 16px;
    cursor: pointer;
}

.closed_section_container__1VGP0 .closed_rsvp_button__cJuh2 svg {
    margin-left: 5px;
}

.closed_section_image__2Hd5f {
    order: -1;
    height: auto;
    width: 100%;
}

.closed_section_logo__39bJ8 {
    width: 300px;
    max-width: 100%;
    margin-top: 15px;
}

.closed_about_event_description__1aJe8 li {
    text-align: left;
    padding-left: 12px;
    padding-bottom: 2px;
}

.closed_about_event_description__1aJe8 li::marker {
    font-weight: bold;
}

@media (min-width: 750px) {
    section {
        display: flex;
    }

    .closed_rsvp_scroll_button__26ZLU {
        display: none;
    }

    .closed_live_event__WL_qp .closed_section_container__1VGP0 {
        text-align: left;
    }

    .closed_about_event__3hJmb {
        padding: 40px 0;
    }

    .closed_section_container__1VGP0 {
        flex-direction: row;
        padding: 0 20px;
        max-width: 975px;
    }

    .closed_section_container__1VGP0 > div {
        width: 100%;
    }

    .closed_section_container__1VGP0 p {
        font-size: 16px;
        line-height: 20px;
    }

    .closed_section_container__1VGP0 .closed_rsvp_button__cJuh2 {
        justify-content: left;
    }

    .closed_section_image__2Hd5f {
        order: 0;
        max-height: 515px;
        height: 100%;
        padding-left: 20px;
        width: auto;
        max-width: 50%;
    }

    .closed_section_logo__39bJ8 {
        margin-top: 0;
    }

    .closed_about_event__3hJmb {
        padding: 60px 0;
    }

    .closed_section_image__2Hd5f {
        padding-left: 50px;
    }

    .closed_section_container__1VGP0 h1 {
        font-size: 40px;
        line-height: 36px;
    }
}

@media (min-width: 1024px) {
    .closed_section_container__1VGP0 h1 {
        font-size: 60px;
        line-height: 56px;
    }

    .closed_section_container__1VGP0 p, .closed_section_container__1VGP0 .closed_rsvp_button__cJuh2 {
        font-size: 18px;
        line-height: 24px;
    }
}

.countdown_countdown_container__bbj9Y {
  font-family: 'Fnord', sans-serif;
  padding: 10px;
  margin: 10px auto 30px auto;
  background-color: #DE9344;
  color: #ffffff;
  border-radius: 12px;
  width: 144px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.countdown_timer_wrapper__3q6_6 {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.countdown_countdown_container__bbj9Y .countdown_description__3ZCR6 {
  margin: 0;
  font-size: 12px;
  color: #fff;
}

.countdown_countdown_unit__2V1U2 {
  display: inline-flex;
  width: 36px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.countdown_countdown_number__3YbFy {
  padding: 0;
  font-size: 22px;
}

.countdown_countdown_label__3_fMx {
  font-family: 'Helvetica New Display', sans-serif;
  font-size: 8px;
  text-transform: lowercase;
}

.countdown_colon__1Fm36 {
  font-size: 22px;
}

@media (min-width: 768px) {
  .countdown_countdown_container__bbj9Y {
    width: 236px;
    height: 90px;
  }

  .countdown_countdown_unit__2V1U2 {
    width: 56px;
  }

  .countdown_countdown_container__bbj9Y .countdown_description__3ZCR6 {
    font-size: 16px;
  }

  .countdown_countdown_label__3_fMx {
    font-size: 10px;
  }

  .countdown_colon__1Fm36, .countdown_countdown_number__3YbFy  {
    font-size: 40px;
  }
}

.event_container__2CplV {
  font-family: 'Helvetica Now Display', sans-serif;
  display: block;
  margin: 0 auto;
  max-width: 1180px;
  width: 100%;
}

.event_container__2CplV h1 {
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  font-weight: 400;
  margin: 20px 0 10px 0;
  letter-spacing: -1px;
  font-family: 'Fnord', sans-serif;
}

.event_container__2CplV h2 {
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  font-weight: 400;
  margin: 0 0 30px 0;
  letter-spacing: -1px;
  font-family: 'Fnord', sans-serif;
}

.event_iframes_container__20uwN {
  display: flex;
  flex-wrap: wrap;
}

.event_video_container__11ugi {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}

.event_chat_container__37Lz1 {
  position: relative;
  height: 450px;
  padding-bottom: 0;
  width: 100%;
}

.event_iframes_container__20uwN iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.event_chat_container__37Lz1 iframe {
  visibility: hidden;
  display: none;
}

.event_chat_container__37Lz1 .event_show_iframe__24fxF {
  visibility: visible;
  display: block;
}

.event_product_overlay__3hTZ4 {
  font-family: 'Fnord', sans-serif;
  width: 40vw;
  height: 25vw;
  max-width: 293px;
  max-height: 125px;
  border-radius: 11px;
  color: #ffffff;
  position: absolute;
  bottom: 55px;
  left: 10px;
  z-index: 2;
  background-color: #9FA402;
  display: flex;
}

.event_product_overlay__3hTZ4 img {
  border-bottom-left-radius: 11px;
  border-top-left-radius: 11px;
  max-width: 45%;
}

.event_buy_button__1V3O- {
  font-family: 'Helvetica New Display', sans-serif;
  background: #EBAF5A;
  color: #000000;
  font-size: 12px;
  border-radius: 4px;
  width: 85px;
  height: 28px;
  border: none;
  cursor: pointer;
  margin-top: 5px;
}

.event_close_button__3V6mj {
  color: #ffffff;
  background: none;
  border: none;
  position: absolute;
  right: 1px;
  top: 4px;
  cursor: pointer;
}

.event_close_button__3V6mj svg {
  width: 15px;
  height: 15px;
  fill: #ffffff;
}

.event_product_data__2nEPF {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

.event_product_data__2nEPF span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event_nav_items_mobile__PhPYt {
  display: flex;
  justify-content: end;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #DE9344;
  font-size: 14px;
  width: 100%;
}

.event_nav_items_desktop__J6svs {
  display: none;
}

.event_nav_items_mobile__PhPYt li {
  cursor: pointer;
  margin: 20px;
}

.event_nav_items_desktop__J6svs li {
  cursor: pointer;
  margin: 20px 0 20px 30px;
}

.event_nav_active__2Uf7r {
  text-decoration: underline;
}

.event_availability__am2MR {
  display: none;
}

.event_availability__am2MR, .event_availability_mobile__3I6RO {
  text-align: left;
  margin: 20px;
  font-size: 12px;
}

.event_product_grid__1oyuN {
  max-width: 1180px;
  width: 100%;
  margin: auto;
}

.event_product_grid__1oyuN ul {
  display: flex;
  list-style: none;
  margin: 2.5vw;
  flex-flow: row wrap;
  padding: 0;
}

.event_product_grid__1oyuN li {
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 1;
  margin: 2.5vw;
  flex-basis: calc(100%/2 - 5vw);
}

.event_product_grid__1oyuN p {
  margin: 0;
}

@media (max-width: 299px) {
  .event_product_overlay__3hTZ4 {
    bottom: 5px !important;
  }
}

@media (max-width: 450px) {
  .event_product_overlay__3hTZ4 {
    width: 45vw;
    height: 20vw;
    left: 5px;
    bottom: 45px;
  }

  .event_product_data__2nEPF {
    font-size: 10px;
    padding: 5px 10px 5px 5px;
  }

  .event_product_data__2nEPF span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event_product_overlay__3hTZ4 .event_buy_button__1V3O- {
    font-size: 9px;
    width: 70px;
    height: 18px;
    margin-top: 5px;
  }

  .event_product_overlay__3hTZ4 .event_close_button__3V6mj {
    padding: 0;
    right: 4px;
    top: 2px;
  }

  .event_product_overlay__3hTZ4 .event_close_button__3V6mj svg {
    width: 10px;
    height: 10px;
  }
}

@media (min-width: 568px) {
  .event_product_grid__1oyuN ul {
    margin: 25px;
  }

  .event_product_grid__1oyuN li {
    margin: 15px;
    flex-basis: calc(100%/3 - 30px);
  }

  .event_product_data__2nEPF {
    padding: 15px;
  }

  .event_product_overlay__3hTZ4 .event_buy_button__1V3O- {
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .event_container__2CplV h1 {
    letter-spacing: -1.94px;
    font-size: 60px;
  }

  .event_product_overlay__3hTZ4 {
    width: 293px;
    height: 125px;
  }

  .event_product_data__2nEPF {
    font-size: 16px;
  }

  .event_buy_button __3im2L{
    width: 93px;
  }

  .event_product_grid__1oyuN ul {
    margin: 20px;
  }

  .event_product_grid__1oyuN li {
    margin: 20px 10px;
    flex-basis: calc(100%/5 - 20px);
  }

}

@media (min-width: 968px) {
  .event_availability_mobile__3I6RO {
    display: none;
  }

  .event_availability__am2MR {
    display: block;
  }

  .event_container__2CplV {
    width: 95%;
  }

  .event_info_container__zqMXo {
    margin-top: 15px;
    font-size: 16px;
  }

  .event_iframes_container__20uwN {
    flex-wrap: nowrap;
  }

  .event_video_container__11ugi {
    padding-bottom: 40.25%;
  }

  .event_chat_container__37Lz1 {
    padding-bottom: 40.25%;
    width: 40%;
    height: auto;
  }

  .event_nav_items_mobile__PhPYt {
    display: none;
  }

  .event_nav_items_desktop__J6svs {
    display: flex;
    justify-content: end;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #DE9344;
    font-size: 16px;
  }

  .event_availability__am2MR {
    text-align: left;
    margin: 0;
    font-size: 14px;
  }

  .event_product_grid__1oyuN ul {
    margin: 20px -20px;
  }

  .event_product_grid__1oyuN li {
    margin: 20px;
    flex-basis: calc(100%/5 - 40px);
  }
}

html, body {
  background-color: #ADADAD;
  font-family: 'Helvetica Now Display', sans-serif;
  margin: 0;
  padding: 0;
}

.App_app__1kX79 {
  display: flex;
  flex-direction: column;
}

header {
  background: #ffffff;
}

.App_header_content__3mfMw {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App_header_content__3mfMw img {
  cursor: pointer;
  height: 50px;
}

.App_container__1MQN3 {
  background-color: #FDF3EC;
  flex-grow: 2;
  padding: 0;
}

@media (min-width: 768px) {
  .App_header_content__3mfMw {
    height: 76px;
  }

  .App_header_content__3mfMw img {
    height: 70px;
  }
}

