.countdown_container {
  font-family: 'Fnord', sans-serif;
  padding: 10px;
  margin: 10px auto 30px auto;
  background-color: #DE9344;
  color: #ffffff;
  border-radius: 12px;
  width: 144px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.timer_wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.countdown_container .description {
  margin: 0;
  font-size: 12px;
  color: #fff;
}

.countdown_unit {
  display: inline-flex;
  width: 36px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.countdown_number {
  padding: 0;
  font-size: 22px;
}

.countdown_label {
  font-family: 'Helvetica New Display', sans-serif;
  font-size: 8px;
  text-transform: lowercase;
}

.colon {
  font-size: 22px;
}

@media (min-width: 768px) {
  .countdown_container {
    width: 236px;
    height: 90px;
  }

  .countdown_unit {
    width: 56px;
  }

  .countdown_container .description {
    font-size: 16px;
  }

  .countdown_label {
    font-size: 10px;
  }

  .colon, .countdown_number  {
    font-size: 40px;
  }
}
